<template>
     <div class="flex items-center mb-4 gap-2">
        <label for="default-radio-1" class="text-md font-medium text-gray-900 dark:text-gray-300">{{ title }}</label>
        <input id="default-radio-1" type="radio" :value="value" name="default-radio"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
        class="text-blue-600 bg-gray-100 border-gray-300">
    </div>
</template>
<script>
export default {
    props:{
         title: String,
         value: Object,
    },
    emits: ["update:modelValue"],
    methods: {
        focus() {
            this.$refs.input.focus();
        },
    },
}
</script>