<template>
  <div class="max-w-md mx-auto p-4 bg-white mt-8 rounded-md">
    <label for="" class="text-2xl">Appointment</label>
    <div class="text-left p-2 relative">
      <label for="">Find Patient</label>
      <InputNew
        type="number"
        v-model="phone"
        @input="onChange"
        class="w-full py-2 p-2 border focus:outline-none"
        placeholder="Phone Number..."
      />
      <div class="-mt-8 right-0 mr-8 absolute">
        <span @click="findDoctor(getUser)" class="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </span>
      </div>
    </div>
    <div class="p-2" v-if="user">
      <InputNew
        :value="user.name"
        class="w-full py-2 p-2 focus:outline-none"
        readonly
      />
    </div>
    <div class="p-2" v-if="appointmentEdit.patient">
      <InputNew
        :value="appointmentEdit.patient.name"
        class="w-full py-2 border p-2 focus:outline-none"
        readonly
      />
    </div>

    <div class="p-2" v-if="getPatient">
      <InputNew
        :value="user.name"
        class="w-full py-2 p-2 focus:outline-none border"
      />
    </div>
    <div v-if="doctorRegister" class="flex flex-col gap-4 p-2">
      <input-new
        type="text"
        class="w-full py-2 p-2 border focus:outline-none"
        placeholder="Patient Name"
      />
      <input-new
        type="number"
        class="w-full py-2 p-2 border focus:outline-none"
        placeholder="Phone Number"
      />
      <input-new
        type="number"
        class="w-full py-2 p-2 border focus:outline-none"
        placeholder="BMDC Number"
      />
    </div>
    <div class="text-left p-1">
      <label for="">Serial</label>
      <InputNew
        type="text"
        v-model="serial"
        class="w-full py-2 p-2 border focus:outline-none"
      />
    </div>
    <div class="text-left p-1">
      <label for="">Appointment Type</label>
      <div class="bg-gray-200 p-2 flex gap-4 md:mx-auto rounded">
        <label
          v-for="support_type in appointmentTypes.support_types"
          :key="support_type.id"
        >
          <div
            :class="{ 'bg-brand !text-white': supportType == support_type.id }"
            class="cursor-pointer bg-white overflow-hidden relative text-left shadow p-2 rounded hover:shadow-lg"
          >
            <input
              type="radio"
              v-model="supportType"
              :value="support_type.id"
              class="absolute -left-full"
              @click="payAble(type.name)"
            />
            <span class="p-2 hover:text-brand">{{ support_type.name }}</span>
          </div>
        </label>
      </div>
    </div>
    <div class="text-left p-1">
      <label for="">Question</label>
      <div class="grid grid-cols-5 gap-2">
        <div class="col-span-full">
          <div
            class="grid grid-cols-5 mb-2"
            v-for="(question, index) in questions"
            :key="index"
          >
            <Textarea
              class="col-span-4 py-2 p-2 border focus:outline-none"
              v-model="questions[index]"
              placeholder="Please share your problem with us to make the counselling session best effective. Your all informations will be kept confidential."
            />
            <button class="col-span-1 text-red-500" @click="remove(index)">
              &#9587;
            </button>
          </div>
        </div>
        <Btn type="button" @click="addQuestions()"> + Add</Btn>
      </div>
      <!-- <Textarea type="text" class="w-full p-2" v-model="question"/> -->
    </div>
    <div class="text-left">
      <label for="">Payable</label>
      <input
        type="text"
        class="w-full py-2 rounded border focus:outline-none p-2"
        v-model="payable"
      />
      <!-- <div class="text-left" v-if="HasPayable">
            <label for="">Payable</label>
            <div class="flex gap-4 p-2">
                <InputRadio title="Yes" value="1" v-model="haspayable"/>
                <InputRadio title="No" value="0"  v-model="haspayable"/>
            </div>
            <div v-if="haspayable == 1">
                <input type="text" class="w-full py-2 rounded" v-model="payable"/>
            </div> -->
    </div>
    <div class="text-left p-1">
      <label for="">Mentor</label>
      <Select
        type="text"
        class="w-full border py-3 bg-white outline-none p-2"
        v-model="request_mentor_id"
        v-if="appointmentTypes.mentor"
      >
        <option value="">Select Mentor</option>
        <option
          v-for="(mentor, index) in appointmentTypes.mentor[supportType]"
          :key="index"
          :value="index"
        >
          {{ mentor.name }}
        </option>
      </Select>
    </div>
    <div class="flex justify-between items-center mt-4">
      <div
        @click="$router.go(-1)"
        class="text-brand rounded-md py-1.5 px-2 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
      </div>
      <Btn class="" type="submit" @click="submitForm()">Submit</Btn>
    </div>
  </div>
</template>

<script>
import Select from "../../../../components/select.vue";
import InputNew from "../../../../components/input-new.vue";
import InputRadio from "../../../../components/input-radio.vue";
import InputText from "../../../../components/input-text.vue";
import Textarea from "../../../../components/input-text-area.vue";
import { Calendar, DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import Btn from "../../../../components/btn.vue";
import axios from "../../../../store/axios";
export default {
  components: {
    Select,
    InputNew,
    Textarea,
    Btn,
    InputText,
    DatePicker,
    Calendar,
    InputRadio,
  },
  data() {
    return {
      user_id: "",
      user: "",
      schedule_id: this.$route.params.scheduleId,
      serial: "",
      question: "",
      payable: "",
      type: "",
      chamberId: "",
      supportType: "",
      phone: "",
      request_mentor_id: "",
      getPatient: false,
      getPatientEdit: true,
      doctorRegister: false,
      HasPayable: false,
      questions: [""],
    };
  },
  created() {
    this.$store.dispatch("adminAppointment/getAppointmentEdit", {
      appointmentId: this.appointmentId,
    });
    this.$store.dispatch("adminAppointment/getAppointmentTypes");
  },

  watch: {
    "$store.state.adminAppointment.appointmentEdits"(data) {
      if (data.appointment) {
        this.user_id = data.appointment.user_id;
        this.serial = data.appointment.serial;
        this.supportType = data.appointment.type;
        if (data.appointment.questions instanceof Array) {
          for (const key in data.appointment.questions) {
            this.questions[key] = data.appointment.questions[key];
          }
        } else {
          this.questions[0] = data.appointment.questions;
        }

        this.request_mentor_id = data.appointment.requested_mentor_id;
      }
    },
  },
  computed: {
    appointmentEdit() {
      const data = this.$store.state.adminAppointment.appointmentEdits;
      return data.appointment || {};
    },
    getUser() {
      return this.phone;
    },
    appointmentTypes() {
      return this.$store.state.adminAppointment.types || "";
    },
    appointmentId() {
      return this.$route.params.appointmentId;
    },
    findUser() {
      return this.$store.state.adminAppointment.findUser || "";
    },
    user() {
      const user = this.findUser.user || "";
      if (user) {
        this.user_id = user.id;
        return user;
      }
    },
  },
  methods: {
    async submitForm() {
      console.log(
        this.user_id,
        this.schedule_id,
        this.supportType,
        this.serial,
        this.questions,
        this.request_mentor_id,
        this.payable
      );
      let appointment = await axios()
        .put(`/api/appointments/${this.appointmentId}`, {
          user_id: this.user_id,
          schedule_id: this.schedule_id,
          type: this.supportType,
          serial: this.serial,
          questions: this.questions,
          requested_mentor_id: this.request_mentor_id,
          payable: this.payable,
        })
        .then(() => {
          this.$toast.success(`Appointment Successfuly Update !!!`, {
            position: "top-right",
            duration: 3000,
          });
          this.$router.push(
            `/admin/schedules/${this.schedule_id}/appointments`
          );
        });
      console.log(appointment);
    },
    findDoctor(phone) {
      this.user = Object.values(this.appointmentTypes.mentor).find((obj) => {
        return obj.phone == phone;
      });
      if (this.user) {
        this.user_id = this.user.id;
        this.getPatient = true;
        this.doctorRegister = false;
        this.getPatientEdit = false;
      } else {
        this.user_id = " ";
        this.getPatient = false;
        this.doctorRegister = true;
        this.getPatientEdit = false;
      }
    },
    payAble(element) {
      if (element === "Psychology") {
        this.HasPayable = true;
      }
      if (element === "Career Counseling") {
        console.log((this.HasPayable = false));
      }
    },
    addQuestions() {
      console.log("asche", this.questions);
      this.questions.push("");
    },

    remove(index) {
      this.questions.splice(index, 1);
    },
    onChange() {
      if (this.phone.length > 10) {
        this.$store.dispatch("adminAppointment/findUser", {
          phone: this.phone,
        });
      }
    },
  },
};
</script>
